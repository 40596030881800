<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12 col-xxl-10 print-area" ref="printArea">
      <ExecutiveBrief
        reportType="Risk"
        reportSubType="Executive Brief"
        @print-page="printPage"
      ></ExecutiveBrief>
    </div>
  </div>
</template>

<script>
import ExecutiveBrief from '../../components/ExecutiveBrief'

export default {
  name: 'RiskExecutiveSummary',
  components: {
    ExecutiveBrief
  },
  methods: {
    printPage() {
      this.$nextTick(() => {
        let pageTitle = document.title
        this.$toasted.clear()
        document.title = 'fin_advisor_report'
        window.print()
        document.title = pageTitle
      })
    }
  }
}
</script>
